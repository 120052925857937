import React, {Component} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import './styleLandscape.css'
import './imageButton.css'
import apply from '../../assets/images/gift/landscape/apply.png'
import check from '../../assets/images/gift/landscape/check.png'
import avatarDefault from '../../assets/images/avatar_male_m.png'
import 'bootstrap/dist/css/bootstrap.css';
import ReCAPTCHA from "react-google-recaptcha";

class GiftLandscape extends Component {
    constructor(props) {
        super(props);
        this.recaptchaRef = React.createRef();
        this.recaptchaKey = "6LcgbJYmAAAAAID0IMz45nwWXA4Z2WSZHymET3vH";
    }

    state = {
        uid: '',
        nameOrUid: '',
        giftcode: '',
        name: '',
        run: false,
        switchOn: true,
        avatarU: avatarDefault,
        needCheck: true
    };

    handleUidChange = (event) => {
        this.setState({nameOrUid: event.target.value, run: false, needCheck: true});
    };

    handleGiftcodeChange = (event) => {
        this.setState({giftcode: event.target.value});
    };

    fetchUserInfo = async () => {
        // await this.recaptchaRef.current.executeAsync();
        // this.recaptchaRef.current.reset();

        const {nameOrUid, switchOn} = this.state;
        let uid = -1;
        if (switchOn === true) {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/user/check-name/${nameOrUid}`)

                const {status, result, reason} = response.data

                if (status === 'KO') {
                    this.setState({uid: '', name: '', avatarU: avatarDefault});
                    swal(reason, '', 'error')
                    return
                }

                uid = result
            } catch (error) {
                this.setState({uid: '', name: '', avatarU: avatarDefault});
                swal('User không tồn tại', '', 'error')
                return
            }
        } else {
            uid = nameOrUid
        }

        const now = Date.now() / 1000;
        const avatarU = `${process.env.REACT_APP_API_ENDPOINT}/game/avatar?id=${uid}&type=m&date=${now}`;
        this.setState({uid: uid, avatarU: avatarU});

        try {
            if (switchOn === false) {
                const response = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/user/get-name/${uid}`);
                const {status, name} = response.data;

                if (name === '') {
                    this.setState({uid: '', name: '', avatarU: avatarDefault});
                    swal('User không tồn tại', '', 'error')

                    return
                }

                if (status === 'OK') {
                    this.setState({name, run: true, needCheck: false});
                } else {
                    this.setState({uid: '', name: '', avatarU: avatarDefault});
                    swal('User không tồn tại', '', 'error')
                }
            } else {
                this.setState({name: nameOrUid, run: true, needCheck: false});
            }
        } catch (error) {
            this.setState({uid: '', name: '', avatarU: avatarDefault});
            swal('User không tồn tại', '', 'error')
        }
    };

    sendGift = async () => {
        const recaptchaValue = await this.recaptchaRef.current.executeAsync();
        this.recaptchaRef.current.reset();

        const {uid, giftcode} = this.state;

        try {
            const uidInt = parseInt(uid);
            const response = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/user/gift-code/pay-giftcode-new`, {
                uid: uidInt, giftcode_id: giftcode, captchaToken: recaptchaValue
            });
            const {status, reason} = response.data;

            if (status === 'OK') {
                swal('Thành công', 'Mã quà tặng được sử dụng thành công', 'success');
            } else {
                swal('Thất bại', reason, 'error');
            }
        } catch (error) {
            swal('Thất bại', '', 'error');
        }
    };

    toggleSwitch = () => {
        this.setState((prevState) => ({
            switchOn: !prevState.switchOn, nameOrUid: '', uid: '', name: '', giftcode: '', needCheck: true, run: false,
        }));
    };

    render() {
        const {nameOrUid, uid, giftcode, name, run, switchOn, avatarU, needCheck} = this.state;

        return (<div className="container justify-content-center">
            <ReCAPTCHA ref={this.recaptchaRef} sitekey={this.recaptchaKey} size="invisible" />

            <div className="hello">
                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col">
                        {switchOn ? (<div className="col text-right text-red">Tên tài khoản</div>) : (
                            <div className="col text-right text-red">ID</div>)}
                    </div>
                    <div className="col-auto">
                        <input type="text" className="c-input-1"
                               value={nameOrUid} onChange={this.handleUidChange}/>
                    </div>
                    <div className="col">
                        <button className="image-button" type="button"
                                onClick={this.fetchUserInfo}
                                disabled={!nameOrUid}>
                            <img className="image-check" src={check} alt="Button Image"/>
                        </button>
                    </div>
                </div>

                <div className="row justify-content-center">
                    <div className="col"></div>
                    <button type="button" className="switchButton" onClick={this.toggleSwitch}>
                        {switchOn ? 'Hoặc tìm theo ID' : 'Hoặc tìm theo tên'}
                    </button>
                    <div className="col"></div>
                </div>

                <div className="row d-flex justify-content-center">
                    <div className="col"></div>
                    <div className="col-auto d-flex align-items-center justify-content-center">
                        {run ? (<div className="ketqua row d-flex align-items-center justify-content-center">
                                <div className="col-4">
                                    <img className="avatar" src={avatarU} alt="avatar"/>
                                </div>
                                <div className="col-8">
                                    <div className="row name">Tên tài khoản:<br/>{name}</div>
                                    <div className="row uid">ID: {uid}</div>
                                </div>
                            </div>) : (<div className="ketqua row d-flex align-items-center justify-content-center">
                                <div className="col-4">
                                    <img className="avatar" src={avatarDefault} alt="avatar"/>
                                </div>
                                <div className="col-8">
                                    <div className="row name">Tên tài khoản:</div>
                                    <div className="row uid">ID:</div>
                                </div>
                            </div>)}

                        <input className="c-input-1 c-input-2 text-center" type="text"
                               aria-label="readonly input example" readOnly/>
                    </div>
                    <div className="col"></div>
                </div>

                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col">
                        <div className="col text-right text-red">Mã quà tặng</div>
                    </div>
                    <div className="col-auto">
                        <input
                            className="c-input-1"
                            type="text"
                            value={giftcode}
                            onChange={this.handleGiftcodeChange}
                        />
                    </div>
                    <div className="col"></div>
                </div>

                <div className="row d-flex justify-content-center align-items-center">
                    <div className="col"></div>
                    <div className="col-4 d-flex justify-content-center align-items-center">
                        <button className="image-button2" type="button"
                                onClick={this.sendGift}
                                disabled={!uid || !giftcode || needCheck}>
                            <img src={apply} alt="Button Image"/>
                        </button>
                    </div>
                    <div className="col"></div>
                </div>
            </div>
        </div>);
    }
}

export default GiftLandscape;
