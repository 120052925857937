import React from 'react';
import {useMediaQuery} from 'react-responsive';
import GiftPortrait from './Gift/GiftPortrait';
import GiftLandscape from './Gift/GiftLandscape';
import './App.css';

function App() {
    const isPortrait = useMediaQuery({orientation: 'portrait'});

    return (
        <div className="App">
            <header className="App-header">
                {isPortrait ? (
                    <GiftPortrait/>
                ) : (
                    <GiftLandscape/>
                )}
            </header>
        </div>
    );
}

export default App;
